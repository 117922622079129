































import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";

const OrderFormVue = Vue.extend({
  props: {
    option: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    body: {
      type: Object,
      required: true,
    },
    cost: {
      type: Number,
      default: 0,
    },
    selected: {
      type: String,
      default: "",
    },
  },
});

@Component({
  name: "OrderFormCardComponent",
})
export default class OrderFormCardComponent extends OrderFormVue {
  @Emit("option-clicked")
  clicked(): string {
    return this.option;
  }

  get isSelected(): boolean {
    return this.selected === this.option;
  }
}
