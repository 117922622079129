




















import { isUUID } from "class-validator";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Thanks extends Vue {
  id = isUUID(this.$route.query.id) ? this.$route.query.id : "";
}
