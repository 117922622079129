

































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { isBoolean, isEmail, isUUID } from "class-validator";
import axios from "axios";
import OrderFormCardComponent from "@/components/OrderFormCard.vue";
import { Route } from "vue-router";

@Component({
  components: {
    OrderFormCardComponent,
  },
})
export default class OrderForm extends Vue {
  id = this.$route.query.id;
  ready = false;

  mounted(): Promise<Route> | void {
    // check client-side if uuid is valid
    if (!isUUID(this.$route.query.id)) {
      return this.$router.replace({
        name: "Home",
        params: { error: "INVALID_ID" },
      });
    } else {
      // contact server to verify valid code
      axios({
        baseURL: "/api",
        method: "GET",
        url: `/order?id=${this.id}`,
        headers: { "Content-Type": "application/json" },
      })
        .then(() => {
          this.ready = true;
        })
        .catch(() => {
          return this.$router.replace({
            name: "Home",
            params: { error: "INVALID_ID" },
          });
        });
    }
  }

  form = {
    manager: "",
    cc: "",
    first: "",
    last: "",
    email: "",
    reports: null,
    additionalComments: "",
  };

  // data
  selected = "";
  validOptions = [
    "with_coaching",
    "with_development_plan",
    "with_additional_coaching_session",
  ];
  pleaseConfirm = false;

  // validation rule arrays
  stringValidationRules = [(v: string): boolean | string => !!v || "Required"];
  emailValidationRules = [
    ...this.stringValidationRules,
    (v: string): boolean | string =>
      isEmail(v) || "Please enter a valid email address",
  ];
  radioGroupValidationRules = [
    (v: boolean): boolean | string => isBoolean(v) || "Please confirm",
  ];

  updateSelection(option: string): void {
    this.selected = option;
    this.pleaseConfirm = false;
  }

  async order(): Promise<void> {
    if (
      !this.validOptions.find((opt) => opt === this.selected) ||
      !this.selected
    ) {
      this.pleaseConfirm = true;
    }
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      try {
        const data = {
          ...this.form,
          programme: this.selected,
        };
        const response = await axios({
          baseURL: "/api",
          method: "POST",
          url: `/order?id=${this.id}`,
          data,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.uuid) {
          this.$router.replace({
            name: "Thanks",
            query: {
              id: this.id,
            },
          });
        }
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
    window.scrollTo(0, 0);
  }
}
