import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Order from "../views/Order.vue";
import Thanks from "../views/Thanks.vue";

Vue.use(VueRouter);

const postpendTitle = "Cerno Limited";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
    meta: {
      title: `Welcome | ${postpendTitle}`,
    },
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    meta: {
      title: `Order | ${postpendTitle}`,
    },
  },
  {
    path: "/thanks",
    name: "Thanks",
    component: Thanks,
    meta: {
      title: `Thanks | ${postpendTitle}`,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to?.meta?.title ?? "Cerno Limited";
  next();
});

export default router;
