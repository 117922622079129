

















import Vue from "vue";
import Component from "vue-class-component";

const HomeVue = Vue.extend({
  props: {
    err: {
      type: String,
      required: false,
      default: "",
    },
  },
});

@Component
export default class Home extends HomeVue {}
